<template>
  <v-chart ref="lineChart" :option="orgOptions" :autoresize="true" style="width: 100%; height: 100%"></v-chart>
</template>

<script>
import 'echarts/lib/chart/line'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/toolbox'
import 'echarts/theme/macarons'
const seriesMax = 2 * 60 * 60
export default {
  data() {
    return {
      series: [],
      orgOptions: {},
      timeList: [],
      bool: false,
    }
  },
  props: {
    interval: Number,
    tableData: Array,
    // 测量值
    itemObject: String,
    itemName: String,

    latest: Object,
    warnValue: Number,
    alarmValue: Number,
    // 点名
    title: String,
  },
  methods: {
    handleTableDataChange() {
      let newData = []

      if (this.itemObject === 'displacement') {
        // 沉降测距值数据 异常数据 影响报警线 因此排除异常数据
        newData = this.tableData.filter((item) => {
          return Math.abs(item[this.itemObject]) < 3000000
        })
      } else {
        newData = this.tableData
      }
      this.series = newData.map((item) => {
        return [item.createTime, item[this.itemObject]]
      })
    },
    changeLatest(e) {
      console.log('需要改变')
      this.latest = e
    },
    setOrgOptions() {
      const markLine = {}
      const itemName = this.title + '(' + this.itemName + ')'
      const warnValue = this.warnValue
      const alarmValue = this.alarmValue
      const alarmValueList = []

      if (warnValue) {
        alarmValueList.push({
          yAxis: -warnValue,
          label: {
            formatter: '低于预警值' + -warnValue,
            color: '#FFA700',
          },
          lineStyle: {
            color: 'rgba(238, 99, 99)',
          },
        })
        alarmValueList.push({
          yAxis: warnValue,
          label: {
            formatter: '高于预警值' + warnValue,
            color: '#FFA700',
          },
          lineStyle: {
            color: 'rgba(238, 99, 99)',
          },
        })
      }
      if (alarmValue) {
        alarmValueList.push({
          yAxis: -alarmValue,
          label: {
            formatter: '低于报警值' + -alarmValue,
            color: '#FC011A',
          },
          lineStyle: {
            color: '#FC011A',
          },
        })
        alarmValueList.push({
          yAxis: alarmValue,
          label: {
            formatter: '高于报警值' + alarmValue,
            color: '#FC011A',
          },
          lineStyle: {
            color: '#FC011A',
          },
        })
      }
      // 根据具体情况显示警报线
      if (
        this.itemObject === 'displacementChange' ||
        this.itemObject === 'axialForceChange' ||
        this.itemObject === 'xDisplacementAccumulateChange' ||
        this.itemObject === 'yDisplacementAccumulateChange' ||
        this.itemObject === 'xAngleAccumulateChange ' ||
        this.itemObject === 'yAngleAccumulateChange '
      ) {
        markLine.data = alarmValueList
      }

      this.orgOptions = {
        title: {
          show: true,
          text: itemName,
          top: '2%',
          left: '5%',
        },
        backgroundColor: '#fff',
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            animation: false,
          },
        },
        grid: {
          top: '10%',
          left: '2%',
          right: '7%',
          bottom: ' 3%',
          show: true,
          borderColor: '#012f4a',
          containLabel: true,
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
        },
        dataZoom: [{ type: 'inside' }],
        toolbox: {
          feature: {
            saveAsImage: {},
          },
          right: '5%',
        },
        yAxis: {
          type: 'value',

          // 修改y轴分割线的颜色
          splitLine: {
            lineStyle: {
              color: '#012f4a',
            },
          },
        },
        series: {
          name: this.itemName,
          type: 'line',
          data: this.series,
          markLine,
          showSymbol: false,
        },
      }
    },
  },
  watch: {
    tableData: {
      handler() {
        this.handleTableDataChange()
        this.setOrgOptions()
      },
      immediate: true,
    },
    // 协议改变时
    itemObject() {
      this.handleTableDataChange()
    },
    interval() {},
    latest: {
      handler() {
        // console.log(this.interval)
        console.log('==>', this.interval, seriesMax / this.interval, this.series.length, this.latest.createTime, this.latest[this.itemObject], this.series[0][0])
        // time不同的时候表示有新数据 才有必要 push ; push 数据以后,超过最大条数，则删去前面的数据

        if (this.series[0][0] !== this.latest.createTime) {
          this.tableData.unshift(this.latest)
          this.series.unshift([this.latest.createTime, this.latest[this.itemObject]])
          this.series.length > seriesMax / this.interval ? this.series.shift() : null
          this.$refs.lineChart.setOption({ series: [{ data: this.series }] })
        }
      },
    },
  },
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss"></style>
