<template>
  <div class="Condition">
    <div class="left">
      <div class="subtitle">
        <i class="iconfont iconanquan"></i>
        工程情况
        <span class="fr" style="font-size: 14px; margin-right: 10px" :class="'docking' + taskInfo.docking">{{ '[' + taskInfo.dockingName + ']' }}</span>
        <span class="fr" v-if="taskInfo.fkSafeStateId === 2" style="font-size: 14px; color: #ffa700; margin-right: 10px">{{ '预警' }}</span>
        <span class="fr" v-else-if="taskInfo.fkSafeStateId === 3" style="font-size: 14px; color: #fc011a; margin-right: 10px">{{ '报警' }}</span>
        <span class="fr" v-else style="font-size: 14px; color: #00ff00; margin-right: 10px">{{ '安全' }}</span>
      </div>

      <div class="subtitle">
        <i class="iconfont iconliebiao"></i>
        项目监测列表
        <span class="fr" style="font-size: 14px; color: #00ff00; margin-right: 10px">{{ taskInfo.state.runningState }}</span>
      </div>

      <div class="control"></div>
      <el-scrollbar>
        <div class="wrapper">
          <div class="testItem" v-for="(item, index) in allGroupInfo" :key="item.deviceProtocol" ref="testItem">
            <!-- 检测项 标题 -->
            <div class="title" @click="unfoldData(index, item.data.length, item.deviceProtocolName)" ref="item">
              <i :class="['iconfont', item.deviceProtocol === 'HighMoldInclinationLItem' ? 'iconqingjiao' : '']" v-show="item.deviceProtocol === 'HighMoldInclinationLItem'"> </i>
              <i :class="['iconfont', item.deviceProtocol === 'HighMoldAxialFItem' ? 'iconccgl-chengzhongsaomiao-2' : '']" v-show="item.deviceProtocol === 'HighMoldAxialFItem'"></i>
              <i :class="['iconfont', item.deviceProtocol === 'HighMoldLaserHItem' ? 'iconjiguangcejuyi' : '']" v-show="item.deviceProtocol === 'HighMoldLaserHItem'"></i>
              <i :class="['iconfont', item.deviceProtocol === 'HighMoldLaserVItem' ? 'iconjiguangcejuyi' : '', 'rotate']" v-show="item.deviceProtocol === 'HighMoldLaserVItem'"></i>

              <i :class="['iconfont', item.deviceProtocol === 'HighMoldInclinationLItemV3' ? 'iconqingjiao' : '']" v-show="item.deviceProtocol === 'HighMoldInclinationLItemV3'"> </i>
              <i :class="['iconfont', item.deviceProtocol === 'HighMoldAxialFItemV3' ? 'iconccgl-chengzhongsaomiao-2' : '']" v-show="item.deviceProtocol === 'HighMoldAxialFItemV3'"></i>
              <i :class="['iconfont', item.deviceProtocol === 'HighMoldLaserHItemV3' ? 'iconjiguangcejuyi' : '']" v-show="item.deviceProtocol === 'HighMoldLaserHItemV3'"></i>
              <i :class="['iconfont', item.deviceProtocol === 'HighMoldLaserVItemV3' ? 'iconjiguangcejuyi' : '', 'rotate']" v-show="item.deviceProtocol === 'HighMoldLaserVItemV3'"></i>

              <span class="text">{{ item.deviceProtocolName }}</span>
              <i class="el-icon-arrow-right icon-el" ref="icon"></i>
            </div>
            <!-- 检测项 测点 -->
            <div class="listBox" ref="listBox">
              <div class="itemList" v-for="(p, i) in item.data" :key="p.pkItemId" @click="findSelectDataFun(item.itemType, p.pkItemId, i)">
                <!-- {{ p.pointName }} -->
                <span class="" v-if="p.alarm === '预警'" style="font-size: 14px; color: #ffa700">{{ p.pointName }}</span>
                <span class="" v-else-if="p.alarm === '报警'" style="font-size: 14px; color: #fc011a">{{ p.pointName }}</span>
                <span class="" v-else style="font-size: 14px; color: #00ff00">{{ p.pointName }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <div class="right">
      <!-- 容器 -->
      <el-scrollbar>
        <div class="right_top">
          <el-carousel :autoplay="false" height="600px">
            <el-carousel-item v-loading="loadingChart">
              <lineChart v-if="bool" ref="lineChart" :interval="interval" :itemName="itemName" :title="titleText" :itemObject="itemProtocol" :tableData="dataList" :latest="latest" :warnValue="warnValue" :alarmValue="alarmValue"></lineChart>
            </el-carousel-item>
            <el-carousel-item>
              <div class="img">
                <img :src="taskInfo.plan ? ip + taskInfo.plan : taskInfo.flatPatternmakingOne ? ip + taskInfo.flatPatternmakingOne : taskInfo.flatPatternmakingTwo ? ip + taskInfo.flatPatternmakingTwo : '../../../assets/image/noImg2.png'" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="right_bottom">
          <el-row>
            <el-button :disabled="isButt" type="primary" @click="openTaskDocking('start')">任务开始</el-button>
            <el-button :disabled="isButt" type="warning" @click="openTaskDocking('pause')">任务暂停</el-button>
            <el-button :disabled="isButt" type="success" @click="openTaskDocking('recover')">任务恢复</el-button>
            <el-button :disabled="isButt" type="info" @click="openTaskDocking('end')">任务结束</el-button>
            <el-button :disabled="isButt" type="danger" @click="openTaskDocking('endAccident')">事故结束</el-button>

            <el-select v-model="itemType" placeholder="选择检测项 查看下表最新数据" @change="changeItemType('change', 0)">
              <el-option v-for="item in allGroupInfo" :key="item.itemType" :label="item.deviceProtocolName" :value="item.itemType"> </el-option>
            </el-select>

            <el-select v-model="itemId" placeholder="查看数据曲线">
              <el-option v-for="item in itemList" :key="item.pkItemId" :label="item.pointName" :value="item.pkItemId"> </el-option>
            </el-select>

            <el-select v-model="itemProtocol" placeholder="请选择查看监测项">
              <el-option v-for="item in protocolList" :key="item.object" :label="item.name" :value="item.object"> </el-option>
            </el-select>

            <el-select v-model="interval" placeholder="请选择数据频率">
              <el-option label="超超高频2s" :value="2"> </el-option>
              <el-option label="超高频5s" :value="5"> </el-option>
              <el-option label="高频30s" :value="30"> </el-option>
            </el-select>

            <el-button type="danger" v-permission="2" style="margin-left: 10px" @click="delAllAbnormalData">一键异常处理</el-button>
          </el-row>

          <div class="table">
            <el-scrollbar>
              <PointList :dataSource="dataSource" :maxHeight="210" :protocol="protocol" :loading="loading"></PointList>
            </el-scrollbar>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { getElementRotate } from '@/utils/getElementRotate.js'
import PointList from '@/components/PointList.vue'
import { findAllPoint, findPointById } from '@/api/Point.js'
import { findPointData, findTaskNewData } from '@/api/data.js'
import { findAllPointV3 } from '@/api/PointV3.js'
import { findTaskByTaskId, clearAbnormalData } from '@/api/Task.js'
import { TaskDocking } from '@/api/Alarm.js'
import lineChart from './line3.vue'
const ip = window.location.origin
// 同组每个点的最新数据
const dataSource = []
const allGroupInfo = [
  {
    deviceProtocolName: 'V_沉降监测',
    deviceProtocol: 'HighMoldLaserVItem',
    groupName: '沉降',
    itemType: 'V',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测',
    deviceProtocol: 'HighMoldAxialFItem',
    groupName: '轴力',
    itemType: 'F',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测',
    deviceProtocol: 'HighMoldInclinationLItem',
    groupName: '倾角',
    itemType: 'L',
    nodeType: 'HL',
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测',
    deviceProtocol: 'HighMoldLaserHItem',
    groupName: '位移',
    itemType: 'H',
    nodeType: 'HL',
    data: [],
  },
  {
    deviceProtocolName: 'V_沉降监测(V3)',
    deviceProtocol: 'HighMoldLaserVItemV3',
    groupName: '沉降',
    itemType: 'VV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测(V3)',
    deviceProtocol: 'HighMoldAxialFItemV3',
    groupName: '轴力',
    itemType: 'FV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测(V3)',
    deviceProtocol: 'HighMoldInclinationLItemV3',
    groupName: '倾角',
    itemType: 'LV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测(V3)',
    deviceProtocol: 'HighMoldLaserHItemV3',
    groupName: '位移',
    itemType: 'HV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
]

export default {
  data() {
    return {
      ip,
      taskInfo: {
        state: {},
      },
      isButt: false,
      currentPage: 0,
      pageSize: 10,
      total: 30,
      loading: false,
      dataSource,
      columns: [],
      optionBin: {},
      allGroupInfo,

      itemList: [],
      itemType: 'V',
      itemId: null,

      protocol: 'HighMoldLaserVItem',

      protocolList: [],
      bool: false,
      timer: null,

      itemProtocol: null,
      itemName: null,

      titleText: '',

      dataList: [],

      latest: {},
      warnValue: null,
      alarmValue: null,

      loadingChart: true,
      timerData: null,
      interval: 5,
    }
  },

  components: { PointList, lineChart },
  watch: {
    interval() {
      // 重新渲染
      this.getDataTimedTask()
    },
    itemId(e) {
      if (!e) {
        this.$message({
          message: '该监测项无测点，请切换检测项重试！！',
          type: 'warning',
        })
        return
      }

      this.findPointByIdFun()
      this.findPointDataFun()
    },
    itemProtocol(e) {
      this.bool = false
      this.protocolList.forEach((item) => {
        if (item.object === e) {
          this.itemName = item.name
          console.log(e, this.itemName)
          setTimeout(() => {
            this.bool = true
          }, 200)
        }
      })
    },
  },
  methods: {
    // 一键移除所以异常数据
    delAllAbnormalData() {
      this.$prompt('请输入<span style="color:red">我确认删除该任务所有异常数据</span>移除数据', '删除异常数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
      })
        .then(({ value }) => {
          console.log(value)
          if (value === '我确认删除该任务所有异常数据') {
            //
            clearAbnormalData(this.$route.params.projectId - 0).then((res) => {
              this.$message({
                type: res.data.code === 200 ? 'success' : 'error',
                message: res.data.message,
              })
            })
          } else {
            this.$message({
              type: 'info',
              message: '用户取消',
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '用户取消',
          })
        })
    },

    unfoldData(index, length) {
      if (getElementRotate(this.$refs.icon[index]) === 0) {
        this.$refs.icon[index].style.transform = 'rotate(90deg)'
        this.$refs.listBox[index].style.height = length * 40 + 'px'
      } else {
        this.$refs.icon[index].style.transform = 'rotate(0deg)'
        this.$refs.listBox[index].style.height = 0 + 'px'
      }
    },
    handleData(type) {
      switch (type) {
        case 'F':
          this.protocolList = [
            {
              name: '测量值(kg)',
              object: 'measurements',
            },
            {
              name: '轴力(N)',
              object: 'axialForce',
            },
            {
              name: '累计变化(N)',
              object: 'axialForceChange',
            },
          ]
          break
        case 'L':
          this.protocolList = [
            {
              name: 'X角度(°)',
              object: 'xAngle',
            },
            {
              name: 'X倾角(‰)',
              object: 'xAngleAccumulateChange',
            },
            {
              name: 'X角度累计变化(°)',
              object: 'xAngleChange',
            },
            {
              name: 'Y角度(°)',
              object: 'yAngle',
            },
            {
              name: 'Y倾角(‰)',
              object: 'yAngleAccumulateChange',
            },
            {
              name: 'Y角度累计变化(°)',
              object: 'yAngleChange',
            },
          ]

          break
        case 'H':
          this.protocolList = [
            {
              name: 'X方向累计位移(mm)',
              object: 'xDisplacementAccumulateChange',
            },
            {
              name: 'Y方向累计位移(mm)',
              object: 'yDisplacementAccumulateChange',
            },
          ]
          break
        case 'V':
          this.protocolList = [
            {
              name: '测距值(mm)',
              object: 'displacement',
            },
            {
              name: '累计位移(mm)',
              object: 'displacementChange',
            },
          ]
          break
        case 'FV3':
          this.protocolList = [
            {
              name: '测量值(kg)',
              object: 'measurements',
            },
            {
              name: '轴力(N)',
              object: 'axialForce',
            },
            {
              name: '累计变化(N)',
              object: 'axialForceChange',
            },
          ]
          break
        case 'LV3':
          this.protocolList = [
            {
              name: 'X角度(°)',
              object: 'xAngle',
            },
            {
              name: 'X倾角(‰)',
              object: 'xAngleAccumulateChange',
            },
            {
              name: 'X角度累计变化(°)',
              object: 'xAngleChange',
            },
            {
              name: 'Y角度(°)',
              object: 'yAngle',
            },
            {
              name: 'Y倾角(‰)',
              object: 'yAngleAccumulateChange',
            },
            {
              name: 'Y角度累计变化(°)',
              object: 'yAngleChange',
            },
          ]

          break
        case 'HV3':
          this.protocolList = [
            {
              name: 'X方向累计位移(mm)',
              object: 'xDisplacementAccumulateChange',
            },
            {
              name: 'Y方向累计位移(mm)',
              object: 'yDisplacementAccumulateChange',
            },
          ]
          break
        case 'VV3':
          this.protocolList = [
            {
              name: '测距值(mm)',
              object: 'displacement',
            },
            {
              name: '累计位移(mm)',
              object: 'displacementChange',
            },
          ]
      }
      this.itemProtocol = this.protocolList[0].object
    },
    // 列表与图表联动功能
    findSelectDataFun(itemType, itemId, index) {
      console.log(itemType, itemId, index)
      this.itemType = itemType
      this.changeItemType('click', index)
    },
    getItemList(e, index) {
      this.allGroupInfo.forEach((item) => {
        if (item.itemType === e) {
          this.itemList = item.data
          //   此处协议  切换底下  显示  不同 的表格内容
          this.protocol = item.deviceProtocol
        }
      })
      this.findTaskNewDataFun()
      console.log('this.itemLIst', this.itemList)
      // 默认一个点

      if (this.itemList.length === 0) {
        return
      }
      this.itemId = this.itemList[index ? index : 0].pkItemId
    },
    changeItemType(type, index) {
      this.handleData(this.itemType)

      switch (type) {
        case 'change':
          this.getItemList(this.itemType, 0)
          break
        case 'click':
          this.getItemList(this.itemType, index)
          break
      }
    },

    //
    openTaskDocking(e) {
      this.$confirm('对接前,请查看是否有待处理的报警信息！！, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.taskOperate(e)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消对接',
          })
        })
    },
    setDockingName() {
      switch (this.taskInfo.docking) {
        case 0:
          this.$set(this.taskInfo, 'dockingName', '未对接')
          break
        case 1:
          this.$set(this.taskInfo, 'dockingName', '正在对接')
          break
        case 2:
          this.$set(this.taskInfo, 'dockingName', '暂停中')
          break
        case 3:
          this.$set(this.taskInfo, 'dockingName', '已恢复')
          break
        case 4:
          this.$set(this.taskInfo, 'dockingName', '已结束')
          break
        case 5:
          this.$set(this.taskInfo, 'dockingName', '事故结束')
          break
      }
    },
    // 根据任务ID查询测点图信息
    async findImgById() {
      const { data: res } = await findTaskByTaskId(this.$route.params.projectId - 0)
      console.log('任务', res.data.list[0])
      this.taskInfo = res.data.list[0]
      this.setDockingName()
      // 有填写监测编号才能进行对接操作
      !this.taskInfo.supervisionPlatformNo ? (this.isButt = true) : (this.isButt = false)
    },
    // 根据测点ID 查询测点信息 并赋值
    async findPointByIdFun() {
      const { data: res } = await findPointById(this.itemId)
      this.titleText = res.data.pointName
      this.alarmValue = res.data.alarmValue
      this.warnValue = res.data.warningValue
    },
    // 查询任务所有测点
    async findAllPointFun() {
      // 查询2代以及2.5代测点
      const { data: res } = await findAllPoint(this.$route.params.projectId - 0)
      // 查询3代测点
      const { data: resV3 } = await findAllPointV3(this.$route.params.projectId - 0)

      this.allGroupInfo[0].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '沉降监测'
      })

      this.allGroupInfo[1].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo[2].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '倾角监测'
      })
      this.allGroupInfo[3].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '位移监测'
      })

      this.allGroupInfo[4].data = resV3.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '沉降监测'
      })

      this.allGroupInfo[5].data = resV3.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo[6].data = resV3.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '倾角监测'
      })
      this.allGroupInfo[7].data = resV3.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '位移监测'
      })

      this.getItemList(this.itemType)
    },
    // 获取任务最新数据
    async findTaskNewDataFun() {
      this.loading = true
      const { data: res } = await findTaskNewData(this.itemType, this.$route.params.projectId - 0)

      let pointList = []
      if (!res.data || res.data.length < 0) {
        pointList = []
      } else {
        pointList = res.data.map((item) => {
          item.pointName = item.point && item.point.pointName ? item.point.pointName : null
          return item
        })
      }
      this.dataSource = pointList
      setTimeout(() => {
        this.loading = false
      }, 200)
    },
    // 获取测点数据
    async findPointDataFun() {
      this.bool = false
      this.loadingChart = true
      let defaultStart = new Date()
      defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 2)
      defaultStart = defaultStart.format('yyyy-MM-dd hh:mm:ss')
      let defaultEnd = new Date().format('yyyy-MM-dd hh:mm:ss')
      const { data: res } = await findPointData(this.itemType, 'Result', this.$route.params.projectId - 0, this.itemId, 0, 99999, defaultStart, defaultEnd)

      this.dataList = res.data.list
      setTimeout(() => {
        this.bool = true
        this.loadingChart = false
      }, 300)
      //   获取数据后 调用定时方法

      if (res.data.list.length === 0) {
        return
      }

      this.getDataTimedTask()
    },
    // 每隔30s更新一次数据
    getDataTimedTask() {
      // 先清除定时器
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.findPointNewDataFun()
        // this.findTaskNewDataFun()
      }, this.interval * 1000 * 30)
    },
    // 获取测点数据 最新一条数据
    async findPointNewDataFun() {
      const { data: res } = await findPointData(this.itemType, 'Result', this.$route.params.projectId - 0, this.itemId, 1, 1)
      console.log('每隔30s刷新一次最新数据==>', res.data.list)
      if (res.data.list.length === 0) {
        return
      }
      this.latest = res.data.list[0]
    },
    async taskOperate(e) {
      const { data: res } = await TaskDocking(this.$route.params.projectId - 0, e)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.taskInfo.docking = res.data
      this.setDockingName()
    },
  },
  created() {
    this.findImgById()
    // 获取对应的数据表格
    this.handleData(this.itemType)
    // 查询数据列表
    this.findAllPointFun()
    // 任务最新数据
    this.findTaskNewDataFun()
  },
  mounted() {},
  beforeRouteLeave(to, from, next) {
    console.log('销毁1-----------------')
    clearInterval(this.timer)
    this.timer = null
    next()
  },
}
</script>

<style scoped lang="scss">
.itemList:hover {
  background-color: #002637;
}

.Condition {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  .docking0 {
    display: none;
  }

  .docking1 {
    color: #66b1ff;
  }

  .docking2 {
    color: #ebb563;
  }

  .docking3 {
    color: #67c23a;
  }

  .docking4 {
    color: #909399;
  }

  .docking5 {
    color: #f56c6c;
  }

  .left {
    width: 220px;
    height: 100%;
    // float: left;
    background-color: #004c6e;

    // .control {
    //   width: 100%;
    //   height: 40px;
    // }
    .subtitle {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      font-size: 16px;
      color: #009fff;
      background-color: #00354d;
      text-indent: 10px;

      &:hover {
        background-color: #002637;
      }
    }

    .state {
      width: 100%;

      // height: 340px;
      // height: 310px;
      .alarm {
        line-height: 40px;
        text-indent: 20px;
        color: #ccc;
      }
    }

    .el-scrollbar {
      height: 100%;
    }

    .el-scrollbar ::v-deep.el-scrollbar__wrap {
      overflow: scroll;
      height: 100% !important;
      overflow-x: hidden !important;
    }

    .wrapper {
      width: 100%;
      //   padding-bottom: 400px;

      overflow: hidden;

      .testItem {
        width: 100%;

        .title {
          height: 50px;
          line-height: 50px;
          color: #fff;
          text-indent: 10px;
          display: flex;

          .iconfont {
            flex: 0 0 30px;
            font-size: 28px;
          }

          .rotate {
            margin-bottom: 10px;
            margin-left: 10px;
            margin-right: -10px;
            transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            /* Internet Explorer */
            -moz-transform: rotate(90deg);
            /* Firefox */
            -webkit-transform: rotate(90deg);
            /* Safari 和 Chrome */
            -o-transform: rotate(90deg);
            /* Opera */
          }

          .text {
            flex: 1;
            height: 50px;
            font-size: 14px;
          }

          .icon-el {
            font-size: 20px;
            flex: 0 0 40px;
            line-height: 50px;
            cursor: default;
            transition: all 0.5s ease;
          }
        }

        .listBox {
          width: 100%;
          height: 0;
          overflow: hidden;
          transition: all 0.5s ease;
        }

        .itemList {
          width: 100%;
          text-indent: 30px;
          height: 40px;
          line-height: 40px;
          color: #0cbd7f;
          // display: none;
        }
      }
    }
  }

  .right {
    width: calc(100% - 220px);
    height: 100%;
    .el-scrollbar {
      height: 100%;
    }

    .el-scrollbar ::v-deep.el-scrollbar__wrap {
      overflow: scroll;
      height: 100% !important;
      overflow-x: hidden !important;
      padding: 10px 15px;
      padding-bottom: 20px;
    }

    .right_top {
      .el-carousel-item {
        height: 100%;
      }

      // flex: 0 0 600px;

      .img {
        width: 100%;
        height: 100%;
        text-align: center;

        img {
          height: calc(100% - 20px);
          margin: 10px auto;
        }
      }
    }

    .right_bottom {
      flex: 1;

      // background-color: #004c6e;
      .el-row {
        // height: 40px;
        margin: 10px;
        .el-button {
          margin-bottom: 10px;
        }
        .el-select {
          margin-left: 10px;
          margin-bottom: 10px;
        }
      }

      ::v-deep td {
        height: 40px;
        padding: 0 !important;
        // background-color: #004c6e;
      }

      ::v-deep th {
        height: 50px;
        padding: 0 !important;
        // background-color: #004c6e;
      }
    }
  }

  .el-select {
    width: 240px;
  }
}
</style>
