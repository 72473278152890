// 获取DOM元素旋转角度的方法
export function getElementRotate(e) {
  var st = window.getComputedStyle(e, null)

  var tr = st.getPropertyValue('-webkit-transform') || st.getPropertyValue('-moz-transform') || st.getPropertyValue('-ms-transform') || st.getPropertyValue('-o-transform') || st.getPropertyValue('transform') || 'FAIL'

  //   return tr
  if (tr === 'none') {
    return 0
  }

  var values = tr.split('(')[1].split(')')[0].split(',')

  var a = values[0]
  var b = values[1]
  //   var c = values[2]
  //   var d = values[3]
  var scale = Math.sqrt(a * a + b * b)
  console.log('Scale: ' + scale)
  //   arc sin, convert from radians to degrees, round
  //   var sin = b / scale
  //   next line works for 30deg but not 130deg (returns 50);
  //   var angle = Math.round(Math.asin(sin) * (180 / Math.PI))
  var angle = Math.round(Math.atan2(b, a) * (180 / Math.PI))
  // console.log('Rotate: ' + angle + 'deg')

  return angle
}
